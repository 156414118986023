// Alignment options
export const alignVariants = {
  left: "text-left",
  right: "text-right",
  center: "text-center",
  inherit: "text-inherit",
};

// Whitespace options
export const whitespaceVariants = {
  normal: "whitespace-normal",
  preWrap: "whitespace-pre-wrap",
  noWrap: "whitespace-nowrap",
  balance: "whitespace-normal text-balance",
};

// Responsive variants
export const responsiveVariants = [ "sm", "md", "lg", "xl" ] as const;
